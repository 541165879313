import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Arrow from '../icons/Arrow'
import { FormattedMessage } from 'react-intl'

export interface ILink {
  label: React.ReactNode
  href: string
  icon?: React.ReactNode
}

export interface ISidebarProps {
  links: ILink[]
  className?: string
}

const QviSidebarNav = ({ links = [], className }: ISidebarProps) => {
  const router = useRouter()
  return (
    <>
      <div className="flex items-center justify-between px-3 border-b py-4font-semibold border-grey-200">
        <Link
          href="/apps"
          className="flex w-full px-2 py-3 my-2 transition-all duration-200 rounded items-centerpx-3 hover:font-bold hover:bg-gray-100"
        >
          <Arrow direction="LEFT" />
          <span className="px-1">All applications</span>
        </Link>
      </div>
      <div className="flex w-full px-6 py-3 pb-0 my-2 font-bold">
        <FormattedMessage id="apps.qviToolkit.title" />
      </div>
      <ul className={`py-4 px-3 ${className}`}>
        {links.map((link, key) => {
          const isActiveClass =
            router.pathname === link.href
              ? 'bg-grey200 font-bold border rounded'
              : ''
          return (
            <li key={key}>
              <Link
                href={link.href}
                className={`${isActiveClass} flex items-center my-2 px-3 py-2 rounded transition-all duration-200 hover:font-bold hover:bg-gray-100`}
              >
                <span className="w-4 h-4 mt-[1px]">{link.icon}</span>
                <span className="px-2">{link.label}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}
export default QviSidebarNav
